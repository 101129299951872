import React from "react";
import { Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { Header } from "../../components/Header";
import Footer from "../../components/Footer";
import { VortexSimulatorProps } from "../../../../interfaces/interfaces";

const styles = StyleSheet.create({
  page: {
    paddingTop: "50px",
    fontSize: 14,
  },
  title: {
    fontSize: 15,
    marginBottom: 0,
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontWeight: 600,
  },
  image: {
    width: "220px",
    marginRight: "140px",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gridcol: {
    display: "flex",
    flexDirection: "column",
  },
  table: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    marginTop: 30,
    marginLeft: 15,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableResults: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    marginTop: 30,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    borderLeft: "0.5px solid black",
    borderTop: "0.5px solid black",
    borderBottom: "0.5px solid black",
  },
  tableRowMain: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2F75B5",
  },

  tableCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: "0.5px solid black",
    borderTopWidth: 0,
  },
  tableColTitle: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: "0.5px solid black",
    borderTopWidth: 0,
  },
  tableColTitleResults: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRight: "0.5px solid black",
    borderTopWidth: 0,
  },
  tableColMain: {
    width: "80%",
    borderStyle: "solid",
    color: "white",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColMainResults: {
    width: "90%",
    borderStyle: "solid",
    color: "white",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
    paddingTop: 1,
    paddingBottom: 1,
    fontFamily: "Times-Roman",
  },
  space: {
    height: 10,
  },
});

interface Props {
  data: VortexSimulatorProps;
}

export const GasSimulatorVortexPage = ({ data }: Props) => {
  return (
    <Page style={styles.page}>
      <Header title={"Gas Simulator"} subtitle={""} />
      <Text style={styles.title}>GAS HANDLER</Text>
      <View style={styles.grid}>
        <View style={styles.table}>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Input</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Fluid Rate (stb/d)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.bfpd}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>BSW (%)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.wCut}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Intake Pressure (psi)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.pip}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>GOR (scf/stb)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.gor}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Fluid and well Data</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Oil API</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.oilApi}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Gas Sp Gravity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.gasSP}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Water Sp. Gravity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.waterSP}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Intake Temp (F)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.temperature}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>THP (psig)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.thp}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Reservoir P.(psig)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.reservoirPressure}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Middle of Perfs (TVD ft)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.middlePerfs}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Pump Depth (TVD ft)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.pumpDepth}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Output</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Oil SPGr</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.oilSP}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>WOR</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.wor}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>GLR (SCF/STB)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.glr}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Z Deviation Factor</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.deviationFactorZ}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>RS</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.rs}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Bo</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.bo}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Bg</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.bg}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Bw</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.bw}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Gas Density (lb/ft3)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.gasDensity}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Liquid Density (lb/ft3)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.liquidDensity}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>
                Interfacial Tension (lb/sec2)
              </Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.interfacialTension}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Production Data</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Pwf (psi)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.pwf}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Productivity Index (stb/psi)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.productivityIndex}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Rates at Surface Conditions</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Qo (STBOPD)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.bopd}</Text>
            </View>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Qw (STBWPD)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.bwpd}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Qg (SCFD)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.gasRate}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Rates at Intake Conditions</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Qo_intake (BOPD)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.qo_intake}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Qw_intake (BWPD)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.qw_intake}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Qg_intake (cu ft/d)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.qg_intake}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>Free Gas Into Pump (%)</Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.freeGasVortex}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Mechanical Conditions</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Tubing Size (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.tubingOd}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Gas Handler Size (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.gasHandlerSize}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Retention Sections</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.retentionSecions}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Body OD (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.bodyOd}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Body ID (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.bodyId}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Dip Tube OD (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.ODdiptube}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Dip Tube ID (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.IDdiptube}</Text>
            </View>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Casing ID (in)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.casingId}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Fluid Velocity</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>
                CROSS SECTION AREA 1: Csg ID-GH OD (in2)
              </Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.crossSectional1}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>
                CROSS SECTION AREA 2: GH ID-Dip Tube OD (in2)
              </Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.crossSectional2}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>
                CROSS SECTION AREA 3: Dip Tube ID (in2)
              </Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.crossSectional3}</Text>
            </View>
          </View>
          <View style={styles.space}></View>
          <View style={styles.tableRowMain}>
            <View style={styles.tableColMain}>
              <Text style={styles.tableCell}>Pressure Drop</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>
                Pressure Drop-Dip Tube 1-1/4 (psi)
              </Text>
            </View>
            <View style={styles.tableColTitleResults}>
              <Text style={styles.tableCell}>{data.deltaP}</Text>
            </View>
          </View>
        </View>
      </View>

      <Footer />
    </Page>
  );
};
