export const simulatorDropPressure = ({
  pip,
  flowRegime,
  correctedQt,
  correctedQg,
  ap,
  correctedLiquidDensity,
  correctedGasDensity,
  correctedQl,
  IDdiptube,
  vT,
  liquidViscosity,
  dipTubeLength,
  correctedFlowRateT,
  avgPressure,
  reynoldsL,
  flowRegimeLM,
  flowRegimeLS,
  flowRegimeVgD,
}: {
  pip: number;
  flowRegime: "Slug" | "Bubble" | "Mist" | "Transition";
  correctedQt: number;
  correctedQg: number;
  ap: number;
  correctedLiquidDensity: number;
  correctedGasDensity: number;
  correctedQl: number;
  IDdiptube: number;
  vT: number;
  liquidViscosity: number;
  dipTubeLength: number;
  correctedFlowRateT: number;
  avgPressure: number;
  reynoldsL: number;
  flowRegimeLM: number;
  flowRegimeLS: number;
  flowRegimeVgD: number;
}) => {
  const frictionFactor = 0.029;
  const vB = 1.75;
  let nB = +(
    (1488 * vB * (IDdiptube / 12) * correctedLiquidDensity) /
    liquidViscosity
  );
  let vBb =
    nB < 3000
      ? +(
          (0.546 + 8.74 * 10 ** -6 * reynoldsL) *
          Math.sqrt(32.17 * (IDdiptube / 12))
        )
      : nB > 8000
      ? +(
          (0.35 + 8.74 * 10 ** -6 * reynoldsL) *
          Math.sqrt(32.17 * (IDdiptube / 12))
        )
      : +(
          0.5 *
            ((0.251 + 8.74 * 10 ** -6 * reynoldsL) *
              Math.sqrt(32.17 * (IDdiptube / 12))) +
          Math.sqrt(
            ((0.251 + 8.74 * 10 ** -6 * reynoldsL) *
              Math.sqrt(32.17 * (IDdiptube / 12))) **
              2 +
              (13.59 * liquidViscosity) /
                (correctedLiquidDensity * Math.sqrt(IDdiptube / 12))
          )
        );
  let liquidDis = +(vT < 10
    ? (0.0127 * Math.log10(liquidViscosity + 1)) / (IDdiptube / 12) ** 1.415 -
      0.284 +
      0.167 * Math.log10(vT) +
      0.113 * Math.log10(IDdiptube / 12)
    : (0.0274 * Math.log10(liquidViscosity + 1)) / (IDdiptube / 12) ** 1.371 +
      0.161 +
      0.569 * Math.log10(IDdiptube / 12) -
      Math.log10(vT) *
        ((0.01 * Math.log10(liquidViscosity + 1)) / (IDdiptube / 12) ** 1.571 +
          0.397 +
          0.63 * Math.log10(IDdiptube / 12)));
  let avgDensity =
    (correctedFlowRateT + correctedLiquidDensity * vBb * ap) /
      (correctedQt + vBb * ap) +
    liquidDis * correctedLiquidDensity;
  let frictionLossGradSlug =
    ((frictionFactor * correctedLiquidDensity * vT ** 2) /
      (2 * 32.17 * (IDdiptube / 12))) *
    ((correctedQl + vBb * ap) / (correctedQt + vBb * ap) + liquidDis);
  let fG = +(1 / (1 + correctedQl / correctedQg));
  let vGg = +(correctedQg / (ap * fG));
  let frictrionLossGradMist =
    (frictionFactor * correctedGasDensity * vGg ** 2) /
    (2 * 32.17 * (IDdiptube / 12));
  if (flowRegime === "Bubble") {
    let fg = +(
      0.5 *
      (1 +
        correctedQt / (0.8 * ap) -
        Math.sqrt(
          (1 + correctedQt / (0.8 * ap)) ** 2 - (4 * correctedQg) / (0.8 * ap)
        ))
    );
    let avgDensity = +(
      (1 - fg) * correctedLiquidDensity +
      fg * correctedGasDensity
    );
    let vL = +(correctedQl / (ap * (1 - fg)));
    let frictionLossGrad = +(
      (frictionFactor * correctedLiquidDensity * vL ** 2) /
      (2 * 32.17 * (IDdiptube / 12))
    );
    let deltaD = +(
      (144 *
        dipTubeLength *
        (1 -
          (correctedFlowRateT * correctedQg) /
            (4637 * (IDdiptube / 12) ** 2 * avgPressure))) /
      (avgDensity + frictionLossGrad)
    );
    let deltaPdeltaD = dipTubeLength / deltaD;
    return +(deltaPdeltaD * dipTubeLength + pip);
  } else if (flowRegime === "Slug") {
    let deltaDd =
      (144 *
        (pip * 0.1) *
        (1 -
          (correctedFlowRateT * correctedQg) /
            (4637 * (IDdiptube / 12) ** 2 * avgPressure))) /
      (avgDensity + frictionLossGradSlug);

    let deltaPdeltaDd = +((pip * 0.1) / deltaDd);
    return +(deltaPdeltaDd * dipTubeLength + pip);
  } else if (flowRegime === "Mist") {
    let avgDensity =
      (1 - fG) * correctedLiquidDensity + fG * correctedGasDensity;
    let deltaDd =
      (144 *
        (pip * 0.1) *
        (1 -
          (correctedFlowRateT * correctedQg) /
            (4637 * (IDdiptube / 12) ** 2 * avgPressure))) /
      (avgDensity + frictrionLossGradMist);
    let deltaPdeltaDd = +((pip * 0.1) / deltaDd);
    return pip + deltaPdeltaDd * dipTubeLength;
  } else {
    let qQ = ap * flowRegimeLM * (correctedLiquidDensity * 0.534) ** (-1 / 4);
    let fgMist = 1 / (1 + correctedQl / qQ);
    let avgDensityMist =
      (1 - fgMist) * correctedLiquidDensity + fgMist * correctedGasDensity;
    let avgDensity2 =
      ((flowRegimeLM - flowRegimeVgD) / (flowRegimeLM - flowRegimeLS)) *
        avgDensity +
      ((flowRegimeVgD - flowRegimeLS) / (flowRegimeLM - flowRegimeLS)) *
        avgDensityMist;
    let frictionLossGradTransition =
      ((flowRegimeLM - flowRegimeVgD) / (flowRegimeLM - flowRegimeLS)) *
        frictionLossGradSlug +
      ((flowRegimeVgD - flowRegimeLS) / (flowRegimeLM - flowRegimeLS)) *
        frictrionLossGradMist;
    let dDeltad =
      (144 *
        (pip * 0.1) *
        (1 -
          (correctedFlowRateT * correctedQg) /
            (4637 * (IDdiptube / 12) ** 2 * avgPressure))) /
      (avgDensity2 + frictionLossGradTransition);
    const dDeltapDeltaD = (pip * 0.1) / dDeltad;
    return pip + dipTubeLength * dDeltapDeltaD;
  }
};
