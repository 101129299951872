import styles from "../components.module.sass";
import { useContext } from "react";
import DataContext from "../../context/DataContext";
import { Button, ButtonGroup, useMediaQuery } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import clsx from "clsx";

export const ProposalDescription = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const {
    onChangeProposalDescription,
    data: { proposalDescription },
  } = useContext(DataContext);

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function () {
        reader.result &&
          typeof reader.result === "string" &&
          onChangeProposalDescription(reader.result);
      };

      reader.onerror = function () {
        console.log();
      };
    }
  };

  return (
    <div className={styles.wellbore3d}>
      <div>
        <ButtonGroup
          variant="outlined"
          sx={{ mb: 2 }}
          orientation={`${matches ? `horizontal` : `vertical`}`}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onChangeProposalDescription("");
            }}
            disabled={!proposalDescription}
          >
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
            Remove
          </Button>
          <Button variant="outlined" color="primary" component="label">
            <ImageIcon fontSize="small" sx={{ mr: 1 }} />
            Upload Image
            <input
              id="input-image"
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(event) => {
                handleUploadImage(event);
                event.target.files = null;
              }}
            />
          </Button>
        </ButtonGroup>
        <div>
          <div className={clsx(styles.center, styles.iframe3d)}>
            {proposalDescription && (
              <img
                src={proposalDescription}
                alt="Tool Description"
                style={{ maxHeight: 500 }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
