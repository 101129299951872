import { Document } from "@react-pdf/renderer";

import { GasSimulatorPage } from "../pages/solution/GasSimulatorPage";
import { SimulatorProps } from "../../../interfaces/interfaces";
import {
  GasSimulatorProps,
  GasSimulatorResultProps,
} from "../../../interfaces/interfaces";
import { GasSimulatorVortexPage } from "../pages/solution/GasSimulatorVortexPage";

interface Props {
  data: GasSimulatorProps;
  results: GasSimulatorResultProps;
  simulator: SimulatorProps;
}

export const PdfGasSimulatorFile = ({ data, results, simulator }: Props) => {
  return (
    <Document>
      <>
        {simulator.gas.vortex ? (
          <GasSimulatorVortexPage data={data as any} />
        ) : (
          <GasSimulatorPage
            data={data}
            results={results}
            simulatorState={simulator}
          />
        )}
      </>
    </Document>
  );
};
